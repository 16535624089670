import { Box, Grid } from "@enerbit/base";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store/store";
import "../../../../assets/css/serviceAccount.scss";
import {
  serviceAccountSelect,
  servicesAccountData,
  setTabSelectedAccessManagement,
  tabSelectedAccessManagement,
} from "../../../../features/serviceAccount/serviceAccountSlice";
import { getAliasServiceGroup } from "../../../../helpers/helperAccounts";
import { reloadServicesAccount } from "../../../../helpers/helperSingleSpa";
import TabsComponent from "../TabsComponent";
import ServiceGroup from "./ServiceGroup";
import ServicesCards from "./ServicesCards";

export type ServiceAccountProps = {
  user_id: string;
};

const ServiceAccount = ({ user_id }: ServiceAccountProps) => {
  const service = useSelector(serviceAccountSelect);
  const servicesAccount = useSelector(servicesAccountData);
  const tabAccessManagement = useSelector(tabSelectedAccessManagement);
  const dispatch = useDispatch<AppDispatch>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setTabSelectedAccessManagement(newValue));
  };

  const tabs = [
    {
      label: "Gestión de accesos",
      component: <div id="single-spa-application:app-access-management"></div>,
    },
  ];

  useEffect(() => {
    if (service != "") {
      reloadServicesAccount(service, tabAccessManagement, true, { user_id });
    }
  }, [tabAccessManagement, servicesAccount]);

  return (
    <Box sx={{ width: "100%", mt: "30px" }}>
      <Grid container spacing={2} sx={{ maxHeight: "84vh" }}>
        <Grid item md={3} xs={4}>
          <ServiceGroup />
        </Grid>
        <Grid item md={9} xs={8}>
          <Box className="Box-account-main">
            <Box className="Know-services" sx={{ mb: "20px" }}>
              Service Group{" "}
              {getAliasServiceGroup(
                servicesAccount.find((e) => e.service_account_id == service)
              )}
            </Box>
            <ServicesCards />
            <Box
              sx={{
                background: "white",
                mt: "20px",
                p: "16px",
                borderRadius: "16px",
              }}
            >
              <TabsComponent
                tabs={tabs}
                value={tabAccessManagement}
                handleChange={handleChange}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceAccount;
