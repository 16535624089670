import { api, getMagicalParamUrl } from "@enerbit/base";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isThirdPartyService } from "../../helpers/thirdPartyServices";
import {
  ServiceAccountState,
  ServicesAccount,
  ServicesCards,
} from "../../models/serviceAccountState";
import { StateStorage } from "../../models/stateStorage";

export const smartUsersInitial = async () => {
  let param = getMagicalParamUrl();

  const res = await api.get(
    "/accounts/service-accounts-relationships/?user_id=" +
      param +
      "&page=0&size=300"
  );
  return res.data.items;
};

export const getCardsServiceAccount = createAsyncThunk(
  "serviceAccount/cardsServiceAccount",
  async (service: string, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    if (state.serviceUser.searchUser) {
      const res = await api.get(
        `/accounts/service-relationships/?service_account_id=${service}&user_id=${state.serviceUser.searchUser.id}`
      );
      return res.data;
    }
  }
);

export const smartInitialAccount = createAsyncThunk(
  "serviceAccount/smart",
  smartUsersInitial
);

const initialState: ServiceAccountState = {
  hasError: false,
  isLoading: false,
  isLoadingCards: false,
  servicesAccountData: [],
  serviceAccountSelect: "",
  servicesAccountDataFilter: [],
  servicesAccountCards: [],
  tabSelectedAccessManagement: 0,
};

export const serviceAccountSlice = createSlice({
  name: "serviceAccount",
  initialState,
  reducers: {
    setDefaultServiceAccount: (state) => {
      state.servicesAccountCards = [];
    },
    setTabSelectedAccessManagement: (state, { payload }) => {
      state.tabSelectedAccessManagement = payload;
    },
    setDefaultServicesAccountData: (state) => {
      state.servicesAccountData = [];
    },
    setServicesAccountDataFilter: (state, { payload }) => {
      state.servicesAccountDataFilter = payload;
    },
    setServicesAccountSelect: (state, { payload }) => {
      state.serviceAccountSelect = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCardsServiceAccount.pending.type, (state) => {
        state.isLoadingCards = true;
      })
      .addCase(
        getCardsServiceAccount.fulfilled.type,
        (state, action: PayloadAction<Array<ServicesCards>>) => {
          const filteredCards = action.payload.filter(
            (e) =>
              e.service_type_name === "electricity-supply" ||
              isThirdPartyService(e.service_type_name)
          );
          if (filteredCards.length > 0) {
            state.servicesAccountCards = filteredCards;
          }
          state.isLoadingCards = false;
        }
      )
      .addCase(getCardsServiceAccount.rejected.type, (state) => {
        state.isLoadingCards = false;
      })
      .addCase(smartInitialAccount.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(
        smartInitialAccount.fulfilled.type,
        (state, action: PayloadAction<Array<ServicesAccount>>) => {
          state.servicesAccountData = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(smartInitialAccount.rejected.type, (state) => {
        state.isLoading = false;
        state.hasError = true;
      });
  },
});

export const {
  setDefaultServiceAccount,
  setDefaultServicesAccountData,
  setServicesAccountDataFilter,
  setServicesAccountSelect,
  setTabSelectedAccessManagement,
} = serviceAccountSlice.actions;

export const hasError = (state: StateStorage) => state.serviceAccount.hasError;
export const isLoading = (state: StateStorage) =>
  state.serviceAccount.isLoading;
export const isLoadingCards = (state: StateStorage) =>
  state.serviceAccount.isLoadingCards;
export const servicesAccountData = (state: StateStorage) =>
  state.serviceAccount.servicesAccountData;
export const servicesAccountCards = (state: StateStorage) =>
  state.serviceAccount.servicesAccountCards;
export const servicesAccountDataFilter = (state: StateStorage) =>
  state.serviceAccount.servicesAccountDataFilter;
export const serviceAccountSelect = (state: StateStorage) =>
  state.serviceAccount.serviceAccountSelect;
export const tabSelectedAccessManagement = (state: StateStorage) =>
  state.serviceAccount.tabSelectedAccessManagement;

export default serviceAccountSlice.reducer;
