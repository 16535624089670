import {
  Box,
  Grid,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  SearchIcon,
  InputAdornment,
} from "@enerbit/base";

const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },
  {
    label: 'The Lord of the Rings: The Return of the King',
    year: 2003,
  },
  { label: 'The Good, the Bad and the Ugly', year: 1966 },
  { label: 'Fight Club', year: 1999 },
  {
    label: 'The Lord of the Rings: The Fellowship of the Ring',
    year: 2001,
  },
  {
    label: 'Star Wars: Episode V - The Empire Strikes Back',
    year: 1980,
  }
];

const FiltersCard = () => {
  return (
    <>
      <Box className="Know-services">
        Servicios del cliente
      </Box>
      <Grid sx={{ flexGrow: 1, mt: "30px" }} container>
        <Grid item xs={12} md={4} lg={4} sx={{ pl: "10px" }}>
          <Box sx={{ mb: "10px" }}>Tipo de servicios</Box>
          <Box className="TextField-without-border-radius Text-field-filters"   >
            <Select value={1} disabled fullWidth >
              <MenuItem value={1}>
                Service Group {1}
              </MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} lg={4} sx={{ pl: "10px" }}>
          <Box sx={{ mb: "10px" }}>Dirección</Box>
          <Box className="TextField-without-border-radius Text-field-filters">
            <Autocomplete
              disabled
              id="combo-box-demo"
              options={top100Films}
              renderInput={(params: any) => //revisar
                <TextField {...params} label=""
                  placeholder="Buscar dirección"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start"> <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4} lg={4} sx={{ pl: "10px" }}>
          <Box sx={{ mb: "10px" }}>Personalización</Box>
          <Box className="TextField-without-border-radius Text-field-filters">
            <Autocomplete
              disabled
              id="combo-box-demo"
              options={top100Films}
              renderInput={(params: any) => //revisar
                <TextField {...params} label=""
                  placeholder="Buscar personalización"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start"> <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default FiltersCard;
