enum thirdPartyServices {
  Security = "billerbit-security",
  StreetLight = "billerbit-street-light",
  InfrastructureFee = "distribution-infrastructure-fee",
}

export default thirdPartyServices;

export const isThirdPartyService = (serviceTypeName: string): boolean => {
  return Object.values(thirdPartyServices).includes(
    serviceTypeName as thirdPartyServices
  );
};
