import {
  Box,
  CircularProgress,
  InputAdornment,
  SearchIcon,
  SupervisedUserCircleOutlinedIcon,
  TextField,
  Typography,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCardsServiceAccount,
  isLoading,
  serviceAccountSelect,
  servicesAccountData,
  servicesAccountDataFilter,
  setDefaultServiceAccount,
  setServicesAccountDataFilter,
  setServicesAccountSelect,
  tabSelectedAccessManagement,
} from "../../../../features/serviceAccount/serviceAccountSlice";
import { searchUser } from "../../../../features/serviceUser/serviceUserSlice";
import {
  getAliasServiceGroup,
  getParamsUrl,
} from "../../../../helpers/helperAccounts";
import { reloadServicesAccount } from "../../../../helpers/helperSingleSpa";
import { ServicesAccount } from "../../../../models/serviceAccountState";
import { AppDispatch } from "../../../../store/store";

const ServiceGroup = () => {
  const service = useSelector(serviceAccountSelect);
  const servicesAccount = useSelector(servicesAccountData);
  const tabAccessManagement = useSelector(tabSelectedAccessManagement);
  const servicesAccountFilter = useSelector(servicesAccountDataFilter);
  const [noRecordsFilter, setNoRecordsFilter] = useState("");
  const loading = useSelector(isLoading);
  const dataUser = useSelector(searchUser);
  const dispatch = useDispatch<AppDispatch>();

  const getCardServices = (serviceAccounId: string) => {
    if (dataUser && service != "") {
      dispatch(getCardsServiceAccount(serviceAccounId));
    }
  };

  const componentServiceGroup = (value: ServicesAccount, index: number) => {
    return (
      <Box
        key={index}
        onClick={() =>
          dispatch(setServicesAccountSelect(value.service_account_id))
        }
        className={
          value.service_account_id === service
            ? "Button-services Button-services-selected"
            : "Button-services"
        }
      >
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box sx={{ width: "10vh" }}>
            <Box className="User-circle-icon">
              <SupervisedUserCircleOutlinedIcon
                sx={{ color: "var(--color-primary)" }}
              />
            </Box>
          </Box>
          <Box sx={{ width: "80vh", mt: "4px" }}>
            <Typography
              sx={{ ml: "10px" }}
              className={
                value.service_account_id === service
                  ? "Button-services-selected"
                  : ""
              }
            >
              Service Group {getAliasServiceGroup(value)}
            </Typography>
          </Box>
          <Box sx={{ width: "10vh" }}>{/* <MoreVertIcon /> */}</Box>
        </Box>
      </Box>
    );
  };

  const filterServiceAccount = (value: string) => {
    let filtered: ServicesAccount[] | string = [];

    if (value != "") {
      filtered = servicesAccount.filter((service) =>
        service.service_account_id.substring(0, 8).includes(value)
      );
      if (filtered.length === 0) {
        setNoRecordsFilter("No se encontraron registros");
      }
    } else {
      setNoRecordsFilter("");
    }

    dispatch(setServicesAccountDataFilter(filtered));
  };

  useEffect(() => {
    if (service != "" && dataUser) {
      dispatch(setDefaultServiceAccount());
      const search = servicesAccount.find(
        (item) => item.service_account_id == service
      );
      if (search) {
        reloadServicesAccount(service, tabAccessManagement, true, {
          user_id: dataUser?.id ?? "",
        });
        getCardServices(service);
      }
    }
  }, [service, dataUser]);

  useEffect(() => {
    if (servicesAccount.length > 0) {
      const serviceGroup = getParamsUrl("serviceGroup");
      if (serviceGroup != "") {
        dispatch(setServicesAccountSelect(serviceGroup));
      } else {
        dispatch(
          setServicesAccountSelect(servicesAccount[0].service_account_id)
        );
      }
    }
  }, [servicesAccount]);

  return (
    <>
      <Box sx={{ overflowX: "auto", maxHeight: "86vh" }}>
        {loading ? (
          <Box className="Loading">
            <CircularProgress />
          </Box>
        ) : (
          <Box className="Button-groups">
            <Box className="Know-services" sx={{ mb: "20px" }}>
              Grupo de servicios
            </Box>
            <Box
              className="TextField-without-border-radius Text-select-field"
              sx={{ mb: "15px" }}
            >
              <TextField
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  filterServiceAccount(event.target.value);
                }}
                placeholder="Buscar"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ width: "100%", borderRadius: "14px" }}
              />
            </Box>
            {noRecordsFilter != "" ? (
              <Box>{noRecordsFilter}</Box>
            ) : servicesAccountFilter.length > 0 ? (
              servicesAccountFilter.map((value, index) =>
                componentServiceGroup(value, index)
              )
            ) : (
              servicesAccount.map((value, index) =>
                componentServiceGroup(value, index)
              )
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default ServiceGroup;
