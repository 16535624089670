import {
  BoltIcon,
  Box,
  CircularProgress,
  Grid,
  ServiceCard,
  enerbitColors,
  translateServiceName,
} from "@enerbit/base";
import { useSelector } from "react-redux";
import { navigateToUrl } from "single-spa";
import { StateStorage } from "src/models/stateStorage";
import {
  isLoadingCards,
  servicesAccountCards,
} from "../../../../features/serviceAccount/serviceAccountSlice";
import FiltersCard from "./FiltersCard";

const ServicesCards = () => {
  const cards = useSelector(servicesAccountCards);
  const loading = useSelector(isLoadingCards);
  const { searchUser } = useSelector(
    (state: StateStorage) => state.serviceUser
  );
  const goToAttention = (serviceId: string, userId: string) => {
    navigateToUrl(
      `/#/electricity-supply-service/attention/${serviceId}?userId=${userId}`
    );
  };
  return (
    <Box className="Services-card-main-accounts">
      <FiltersCard />
      {loading ? (
        <Box className="Loading">
          <CircularProgress />
        </Box>
      ) : (
        <Grid className="Grid-service-cards" container>
          {cards &&
            cards.map((value, index: number) => (
              <ServiceCard
                onClick={() =>
                  value.service_type_name === "electricity-supply"
                    ? goToAttention(value.service_id, searchUser?.id || "")
                    : null
                }
                sx={{
                  cursor:
                    value.service_type_name === "electricity-supply"
                      ? "pointer"
                      : "not-allowed !important",
                  minHeight: "120px !important",
                }}
                key={index}
                background={
                  value.service_type_name === "electricity-supply"
                    ? enerbitColors.secondary.orange.main
                    : enerbitColors.primary.main
                }
                icon={<BoltIcon sx={{ fontSize: "31px", color: "white" }} />}
                container={
                  <>
                    <Box className="Service-card-title">
                      {translateServiceName(value.service_type_name)}
                    </Box>
                    <Box>{value.estate_address}</Box>
                    <Box>{value.estate_personalization}</Box>
                  </>
                }
              />
            ))}
        </Grid>
      )}
    </Box>
  );
};

export default ServicesCards;
